import React, { useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    justifyContent: "center",
    padding: theme.spacing(0, 4),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export const LoginPage = ({ onLogin, loading }) => {
  const classes = useStyles();
  const [showPassword, handleShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [credentials, setCredentials] = useState({
    identifier: "",
    password: "",
  });

  const handleChange = (field) => (e) => {
    setCredentials({ ...credentials, [field]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onLogin(credentials);
    } catch (e) {
      setError("Неверный email или пароль!");
    }
  };

  const handleErrorClose = () => {
    setError(null);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={true}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange("identifier")}
            fullWidth
            margin="normal"
            label="Email"
            variant="outlined"
            value={credentials.identifier}
          />
          <TextField
            onChange={handleChange("password")}
            fullWidth
            margin="normal"
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={credentials.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            size="large"
            disabled={loading}
            className={classes.submitButton}
          >
            Войти
          </Button>
        </form>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
