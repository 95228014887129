import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosButton from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";

export const Header = ({ actionIcon = null, title }) => {
  const history = useHistory();
  const action = actionIcon || <Box width="48px" />;

  return (
    <Box display="flex" alignItems="center" p={1} color="background.paper">
      <IconButton
        color="inherit"
        onClick={() => {
          history.push("/");
        }}
      >
        <ArrowBackIosButton color="inherit" />
      </IconButton>
      <Box display="flex" justifyContent="center" flexGrow={1} minWidth="1px">
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Box>
      {action}
    </Box>
  );
};
