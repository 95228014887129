import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Header } from "./Header";

const useStyles = makeStyles((theme) => ({
  offset: {
    height: theme.spacing(8),
  },
  appBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[8],
  },
}));

export const Page = ({ headerProps, children }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.appBar}>
        <Header {...headerProps} />
      </div>
      <div className={classes.offset} />
      <Box p={2}>{children}</Box>
    </Fragment>
  );
};
