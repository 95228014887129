import { object, string, array, boolean } from "yup";

export const schemaWorkout = object({
  user: string(),
  author: string(),
  enabled: boolean(),
  date: string().typeError("Запоните дату тренировка"),
  title: string().required("Запоните название тренировка"),
  exercise: array()
    .of(
      object({
        title: string().required("Запоните название для всех упражнений"),
        description: string(),
      })
    )
    .required(),
});
