import React, { Fragment, useState } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CardActionArea from "@material-ui/core/CardActionArea";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/MoreVert";
import NotesIcon from "@material-ui/icons/Notes";
import VideocamIcon from "@material-ui/icons/Videocam";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { makeStyles } from "@material-ui/core/styles";
import every from "lodash/every";
import { useWorkout } from "../../hooks/useWorkouts";
import { useMessageCounts } from "../../hooks/useMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1.5),
    overflow: "hidden",
    transition: "background-color 300ms ease",
  },
  count: {
    marginRight: theme.spacing(1),
  },
  loading: {
    background: theme.palette.grey[200],
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  done: {
    color: theme.palette.primary.main,
  },
  undone: {
    color: theme.palette.grey[300],
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: theme.spacing(8),
  },
  exercise: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: "hidden",
    minWidth: 0,
  },
}));

export const Workout = ({ value, canEdit, onChangeAction }) => {
  const classes = useStyles();
  const { onUpdate } = useWorkout();
  const [loading, setLoading] = useState(false);
  const doneAll = every(value.exercise, (item) => item.done === true);
  const counts = useMessageCounts(value);

  const handleDoneAllToggle = async () => {
    setLoading(true);
    await onUpdate({
      id: value.id,
      exercise: value.exercise.map(({ id }) => ({
        id,
        done: !doneAll,
      })),
    });
    setLoading(false);
  };

  const handleDoneToggle = async (exerciseId) => {
    setLoading(true);
    await onUpdate({
      id: value.id,
      exercise: value.exercise.map(({ id, done }) => ({
        id,
        done: id === exerciseId ? !done : done,
      })),
    });
    setLoading(false);
  };

  return (
    <Fragment>
      <Paper
        className={clsx(classes.root, {
          [classes.loading]: loading,
        })}
        elevation={6}
      >
        <Box display="flex" alignItems="center">
          <div className={classes.wrapper}>
            <IconButton disabled={loading} onClick={handleDoneAllToggle}>
              <DoneAllIcon
                className={doneAll ? classes.done : classes.undone}
              />
            </IconButton>
          </div>
          <Typography noWrap className={classes.title} variant="button">
            {value.title}
          </Typography>
          {canEdit && (
            <IconButton
              disabled={loading}
              onClick={() => {
                onChangeAction(`menu-${value.id}`);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          {value.exercise.map((exercise) => (
            <Fragment key={exercise.id}>
              <Divider light />
              <Box display="flex" alignItems="center">
                <div className={classes.wrapper}>
                  <IconButton
                    onClick={() => handleDoneToggle(exercise.id)}
                    disabled={loading}
                  >
                    <DoneIcon
                      fontSize="large"
                      className={exercise.done ? classes.done : classes.undone}
                    />
                  </IconButton>
                </div>
                <CardActionArea
                  className={classes.exercise}
                  onClick={() => {
                    onChangeAction(`chat-${exercise.id}`);
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    {exercise.title}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {exercise.description}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    pt={1}
                  >
                    {exercise.attach.length > 0 && <VideocamIcon color="action" />}
                    {counts[exercise.id] > 0 && <NotesIcon color="action" />}
                  </Box>
                </CardActionArea>
              </Box>
            </Fragment>
          ))}
        </Box>
      </Paper>
    </Fragment>
  );
};
