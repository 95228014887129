import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import get from "lodash/get";
import Slide from "@material-ui/core/Slide";
import { useStore } from "../../hooks/useStore";
import { useWorkout } from "../../hooks/useWorkouts";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const WorkoutMenu = ({ open, value, onChangeAction }) => {
  const {
    state: { me },
  } = useStore();
  const { onUpdate } = useWorkout();
  const canEdit = get(value, "author.id") === me.id;

  const handleClose = () => {
    onChangeAction(null);
  };

  const handleCancel = async () => {
    // eslint-disable-next-line
    if (confirm("Удалить тренировку?")) {
      await onUpdate({ id: value.id, enabled: false });
    }
    onChangeAction(null);
  };

  return (
    <Fragment>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        onClose={handleClose}
        open={open}
        maxWidth="md"
      >
        <DialogTitle>{get(value, "title")}</DialogTitle>
        <List>
          <ListItem
            button
            disabled={!canEdit}
            onClick={() => onChangeAction(`update-${value.id}`)}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Редактировать" />
          </ListItem>
          <ListItem button onClick={() => onChangeAction(`clone-${value.id}`)}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Скопировать" />
          </ListItem>
          <ListItem disabled={!canEdit} button onClick={handleCancel}>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText primary="Удалить" />
          </ListItem>
        </List>
      </Dialog>
    </Fragment>
  );
};
