import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";

import { useStore } from "../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  username: {
    textTransform: "uppercase",
  },
}));

export const UserHeader = () => {
  const classes = useStyles();
  const {
    state: { me },
    actions: { logout },
  } = useStore();

  return (
    <Box display="flex" px={2} py={4} alignItems="center">
      <Avatar className={classes.avatar}>
        <PersonIcon fontSize="large" />
      </Avatar>
      <Box pl={3} flexGrow={1}>
        <Typography className={classes.username} variant="h6">
          {me.username}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {me.email}
        </Typography>
      </Box>
      <IconButton onClick={logout}>
        <LogoutIcon color="primary" />
      </IconButton>
    </Box>
  );
};
