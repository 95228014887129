import dayjs from "dayjs";
import "dayjs/locale/ru";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale("ru");
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);

export const toIsoDate = (date) => date.format("YYYY-MM-DD");
export const today = toIsoDate(dayjs());

export default dayjs;
