import React from "react";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useMutation, gql } from "@apollo/client";

const UPLOAD_FILE = gql`
  mutation($file: Upload!, $ref: String, $refId: ID, $field: String) {
    upload(file: $file, ref: $ref, refId: $refId, field: $field) {
      id
      url
      mime
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  loading: {
    margin: theme.spacing(1.5),
    display: "block",
  },
  progress: {
    display: "block",
  },
}));

export const UploadFile = ({ onStart, onComplete, disabled }) => {
  const classes = useStyles();
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const handleChange = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (!validity) {
      return;
    }

    onStart && onStart();
    let media;

    try {
      const { data } = await uploadFile({
        variables: {
          file,
        },
      });
      media = data.upload;
    } catch (e) {
      media = null;
    }

    onComplete && onComplete(media);
  };

  if (disabled) {
    return (
      <div className={classes.loading}>
        <CircularProgress
          className={classes.progress}
          size={24}
          color="primary"
        />
      </div>
    );
  }

  return (
    <div>
      <input
        className={classes.input}
        onChange={handleChange}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" component="span">
          <PhotoCameraIcon />
        </IconButton>
      </label>
    </div>
  );
};
