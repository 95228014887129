import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import EventIcon from "@material-ui/icons/Event";
import LocalDiningIcon from "@material-ui/icons/LocalDining";

import { UserHeader } from "../components/UserHeader";
import { useStore } from "../hooks/useStore";
import { today } from "../services/dayjs";

const useStyles = makeStyles((theme) => ({
  list: {
    margin: theme.spacing(0, 2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
  },
}));

export const MainPage = () => {
  const classes = useStyles();
  const {
    state: { me },
  } = useStore();

  return (
    <Fragment>
      <UserHeader />
      <div className={classes.list}>
        <List>
          <ListItem button component={Link} to={`/calendar/${me.id}/${today}/`}>
            <ListItemAvatar>
              <Avatar>
                <EventIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Календарь тренировок" />
            <NavigateNextIcon />
          </ListItem>
          <ListItem button component={Link} to={`/diet/${me.id}/`}>
            <ListItemAvatar>
              <Avatar>
                <LocalDiningIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Питание" />
            <NavigateNextIcon />
          </ListItem>
          <ListItem button component={Link} to={`/profile/${me.id}/`}>
            <ListItemAvatar>
              <Avatar>
                <FaceIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Профиль" />
            <NavigateNextIcon />
          </ListItem>
        </List>
      </div>
    </Fragment>
  );
};
