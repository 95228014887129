import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme } from "../theme";

export const Search = ({ onChange, value }) => {
  return (
    <Box p={1}>
      <ThemeProvider theme={darkTheme}>
        <TextField
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          variant="outlined"
          size="small"
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value.length === 0 ? (
                  <SearchIcon />
                ) : (
                  <ClearIcon onClick={() => onChange("")} />
                )}
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </Box>
  );
};
