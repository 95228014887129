import React, { useState, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useMeals } from "../../hooks/useMeal";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const DietForm = ({ initialValue, onSubmit }) => {
  const [formData, setFormData] = useState({
    ...initialValue,
    meals: initialValue.meals.map(({ id }) => id),
  });
  const [isLoading, setLoading] = useState(false);
  const { mealsHash } = useMeals();

  const handleChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(formData);
  };

  return (
    <Fragment>
      <TextField
        inputProps={{ maxLength: 5000 }}
        onChange={handleChange("diet")}
        size="small"
        fullWidth
        multiline
        rows={16}
        margin="normal"
        label="Питание"
        variant="outlined"
        type="text"
        value={formData.diet}
      />
      <Autocomplete
        multiple
        value={formData.meals}
        ChipProps={{
          color: "primary",
          size: "small",
          style: { maxWidth: 180 },
        }}
        filterSelectedOptions
        options={Object.keys(mealsHash)}
        getOptionLabel={(option) => mealsHash[option]}
        onChange={(e, value) => {
          setFormData({ ...formData, meals: value });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Продукты питания"
            size="small"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Box mt={2}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          disabled={isLoading}
        >
          Сохранить
        </Button>
      </Box>
    </Fragment>
  );
};
