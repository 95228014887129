import React, { useState, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";

export const ProfileForm = ({ initialValue, onSubmit }) => {
  const [formData, setFormData] = useState(initialValue);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(formData);
  };

  return (
    <Fragment>
      <FormControl component="fieldset">
        <RadioGroup
          row
          name="gender"
          value={formData.gender}
          onChange={handleChange("gender")}
        >
          <FormControlLabel value="MALE" control={<Radio />} label="Мужчина" />
          <FormControlLabel
            value="FEMALE"
            control={<Radio />}
            label="Женщина"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        onChange={handleChange("username")}
        size="small"
        fullWidth
        margin="normal"
        label="Ваше имя"
        variant="outlined"
        value={formData.username}
        inputProps={{ maxLength: 32 }}
      />
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">кг</InputAdornment>,
        }}
        inputProps={{ min: 40, max: 220 }}
        onChange={handleChange("weight")}
        fullWidth
        label="Вес"
        margin="normal"
        size="small"
        type="number"
        variant="outlined"
        value={formData.weight}
      />
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">см</InputAdornment>,
        }}
        inputProps={{ min: 140, max: 220 }}
        onChange={handleChange("height")}
        fullWidth
        label="Рост"
        margin="normal"
        size="small"
        type="number"
        variant="outlined"
        value={formData.height}
      />
      <TextField
        onChange={handleChange("birthDate")}
        size="small"
        fullWidth
        margin="normal"
        label="Дата рождения"
        variant="outlined"
        type="date"
        value={formData.birthDate}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        inputProps={{ maxLength: 5000 }}
        onChange={handleChange("description")}
        size="small"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        label="Противопоказания и ограничения"
        variant="outlined"
        type="text"
        value={formData.description}
      />
      <Box mt={2}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          disabled={isLoading}
        >
          Сохранить
        </Button>
      </Box>
    </Fragment>
  );
};
