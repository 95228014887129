import React from "react";

import { Page } from "../components/Page";
import { DietForm } from "../components/Profile/DietForm";
import { DietView } from "../components/Profile/DietView";
import { Loading } from "../components/Loading";
import { useSettings } from "../hooks/useSettings";

export const DietPage = ({ userId, view }) => {
  const { diet, email, loading, onUpdateDiet } = useSettings({
    userId,
  });

  if (view) {
    return (
      <Page headerProps={{ title: "Питание" }}>
        {loading ? <Loading /> : <DietView {...diet} />}
      </Page>
    );
  }

  return (
    <Page headerProps={{ title: email }}>
      {loading ? (
        <Loading />
      ) : (
        <DietForm initialValue={diet} onSubmit={onUpdateDiet} />
      )}
    </Page>
  );
};
