import { useMutation, useQuery, gql } from "@apollo/client";
import { useStore } from "./useStore";
import get from "lodash/get";
import dayjs from "../services/dayjs";

export const MESSAGES = gql`
  query messages($where: JSON!) {
    messages(where: $where) {
      id
      createdAt
      media {
        id
        url
        mime
      }
      author {
        id
        username
      }
      message
    }
  }
`;

export const MESSAGES_COUNT = gql`
  query messagesCount($where: JSON!) {
    messages(where: $where) {
      id
      uuid
    }
  }
`;

const MESSAGE_CREATE = gql`
  mutation createMessage($input: createMessageInput!) {
    createMessage(input: $input) {
      message {
        id
      }
    }
  }
`;

const MESSAGE_UPDATE = gql`
  mutation updateMessage($input: updateMessageInput!) {
    updateMessage(input: $input) {
      message {
        id
      }
    }
  }
`;

export const useMessages = ({ uuid }) => {
  const { data } = useQuery(MESSAGES, {
    variables: {
      where: {
        uuid,
        enabled: true,
      },
    },
  });

  const messages = (get(data, "messages", []) || [])
    .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    .map((message) => ({
      ...message,
      date: dayjs(message.createdAt).fromNow(),
    }));

  return { messages };
};

export const useMessageCounts = (workout) => {
  const counts = {};
  const { data } = useQuery(MESSAGES_COUNT, {
    variables: {
      where: {
        uuid_in: workout.exercise.map(({ id }) => {
          counts[id] = 0;
          return `exercise::${id}`;
        }),
        enabled: true,
      },
    },
  });

  get(data, "messages", []).forEach(({ uuid }) => {
    const id = uuid.split("::")[1];

    counts[id]++;
  });

  return counts;
};

export const useMessage = ({ uuid }) => {
  const {
    state: { me },
  } = useStore();

  const [createMessage] = useMutation(MESSAGE_CREATE, {
    refetchQueries: ["messages", "messagesCount"],
  });
  const [updateMessage] = useMutation(MESSAGE_UPDATE, {
    refetchQueries: ["messages", "messagesCount"],
  });

  const onCreate = async (data) => {
    await createMessage({
      variables: { input: { data: { ...data, author: me.id, uuid } } },
    });
  };

  const onUpdate = async ({ id, ...data }) => {
    await updateMessage({
      variables: { input: { data: { ...data, uuid }, where: { id } } },
    });
  };

  return { onCreate, onUpdate };
};
