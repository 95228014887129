import React, { useState } from "react";
import set from "lodash/fp/set";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteForeverButton from "@material-ui/icons/DeleteForever";
import { DescriptionField, TitleField } from "./Fields";
import { schemaExercise } from "./schema";
import { UploadFile } from "../UploadFile";
import { Media } from "../Media";
import { useStore } from "../../hooks/useStore";

export const ExerciseForm = ({ value, onChange, onSubmit, loading }) => {
  const {
    state: { me },
  } = useStore();
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const handleForm = (field) => (val) => {
    onChange(set(field, val, value));
  };

  const handleUploadStart = async () => {
    setUploading(true);
  };

  const handleUploadComplete = async (media) => {
    if (media) {
      onChange(set("media", [...value.media, media], value));
    }

    setUploading(false);
  };

  const handleSubmit = async () => {
    try {
      await schemaExercise.validate(value).then(onSubmit);
    } catch (e) {
      setError(e.message);
    }
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleCancel = async () => {
    // eslint-disable-next-line
    if (confirm("Удалить упражнение?")) {
      await onSubmit({ id: value.id, enabled: false });
    }
  };

  const handleRemoveMedia = (id) => {
    // eslint-disable-next-line
    if (confirm("Удалить фото/видео?")) {
      onChange(
        set(
          "media",
          value.media.filter((media) => media.id !== id),
          value
        )
      );
    }
  };

  if (!value) {
    return null;
  }

  const disableDelete = !value.id || me.id !== value.author.id;

  return (
    <FormGroup>
      <TitleField value={value.title} onChange={handleForm("title")} />
      <DescriptionField
        value={value.description}
        onChange={handleForm("description")}
      />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1}>
        <UploadFile
          disabled={uploading}
          onStart={handleUploadStart}
          onComplete={handleUploadComplete}
        />
      </Box>
      {value.media.map((media) => (
        <Box key={media.id} mb={2}>
          <Media media={media} />
          <DeleteForeverButton
            fontSize="small"
            color="primary"
            onClick={() => handleRemoveMedia(media.id)}
          />
        </Box>
      ))}
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          onClick={handleCancel}
          disabled={disableDelete}
          size="small"
          variant="outlined"
          startIcon={<ClearIcon />}
        >
          Удалить
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Сохранить
        </Button>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </FormGroup>
  );
};
