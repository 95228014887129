import { useMutation, useQuery, gql } from "@apollo/client";
import { useStore } from "./useStore";
import { clearToken, setToken } from "../services/token";

const SELF = gql`
  query self {
    self {
      id
      username
      email
      role {
        type
      }
      users {
        id
        username
        email
      }
    }
  }
`;

const LOGIN = gql`
  mutation LoginUser($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
    }
  }
`;

export const useAuth = () => {
  const { actions } = useStore();
  const [login, { loading }] = useMutation(LOGIN);
  const { loading: initialLoading, refetch } = useQuery(SELF, {
    onCompleted: ({ self }) => {
      if (!self) {
        clearToken();
        return;
      }

      actions.login(self);
    },
  });

  const onLogin = async (input) => {
    const { data, errors } = await login({ variables: { input } });

    if (errors) {
      throw errors;
    }

    setToken(data.login.jwt);
    refetch();
  };

  return { initialLoading, loading, onLogin };
};
