import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../Header";
import { Search } from "../Search";

const useStyles = makeStyles((theme) => ({
  offset: {
    height: theme.spacing(16),
  },
  appBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[8],
  },
}));

export const MealHeader = ({ searchValue, onChangeSearchValue }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.appBar}>
        <Header title="Продукты питания" />
        <Search value={searchValue} onChange={onChangeSearchValue} />
      </div>
      <div className={classes.offset} />
    </Fragment>
  );
};
