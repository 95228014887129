import { useMutation, useQuery, gql } from "@apollo/client";
import get from "lodash/get";
import { useStore } from "./useStore";
import { toIsoDate } from "../services/dayjs";

const WORKOUTS = gql`
  query workouts($where: JSON!) {
    workouts(where: $where) {
      id
      title
      date
      user {
        id
      }
      author {
        id
      }
      exercise {
        id
        title
        description
        done
        attach {
          id
          title
          description
          enabled
          media {
            id
            url
            mime
          }
        }
      }
    }
  }
`;

const WORKOUTS_BY_WEEK = gql`
  query workoutsByWeek($where: JSON!) {
    workouts(where: $where) {
      id
      date
    }
  }
`;

export const useWorkouts = ({ day, userId }) => {
  const weekStart = day.startOf("week");

  const { data } = useQuery(WORKOUTS, {
    variables: {
      where: {
        date: toIsoDate(day),
        user: userId,
        enabled: true,
      },
    },
  });

  const { data: weekData } = useQuery(WORKOUTS_BY_WEEK, {
    variables: {
      where: {
        date_gte: toIsoDate(weekStart),
        date_lte: toIsoDate(weekStart.add(7, "day")),
        user: userId,
        enabled: true,
      },
    },
  });

  const workoutsByWeek = get(weekData, "workouts", []).map(({ date }) => date);
  const exercises = {};
  const workouts = get(data, "workouts", []).map((workout) => ({
    ...workout,
    exercise: workout.exercise.map((item) => {
      exercises[item.id] = item;
      return {
        ...item,
        attach: item.attach.map(({ id }) => id),
      };
    }),
  }));

  return { workouts, exercises, workoutsByWeek };
};

const WORKOUT_CREATE = gql`
  mutation createWorkout($input: createWorkoutInput!) {
    createWorkout(input: $input) {
      workout {
        id
      }
    }
  }
`;

const WORKOUT_UPDATE = gql`
  mutation updateWorkout($input: updateWorkoutInput!) {
    updateWorkout(input: $input) {
      workout {
        id
      }
    }
  }
`;

export const useWorkout = () => {
  const {
    state: { me },
  } = useStore();
  const [createWorkout] = useMutation(WORKOUT_CREATE, {
    refetchQueries: ["workouts", "workoutsByWeek"],
  });
  const [updateWorkout] = useMutation(WORKOUT_UPDATE, {
    refetchQueries: ["workouts", "workoutsByWeek"],
  });

  const onCreate = async (data) => {
    await createWorkout({
      variables: {
        input: { data: { ...data, author: me.id } },
      },
    });
  };

  const onUpdate = async ({ id, user, author, ...data }) => {
    await updateWorkout({
      variables: {
        input: {
          where: { id },
          data,
        },
      },
    });
  };

  return { onCreate, onUpdate };
};
