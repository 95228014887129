import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ReactPlayer from "react-player";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: "100%",
  },
}));

export const Media = ({ media }) => {
  const classes = useStyles();
  const url = new URL(media.url);
  url.hostname = "sportday.b-cdn.net";

  /* eslint-disable jsx-a11y/alt-text */
  let view = (
    <Zoom>
      <img role="presentation" className={classes.image} src={url.toJSON()} />
    </Zoom>
  );

  if (media.mime.indexOf("audio") === 0) {
    view = (
      <ReactPlayer
        width="100%"
        height="64px"
        controls={true}
        url={url.toJSON()}
      />
    );
  }

  if (media.mime === "video/mp4") {
    view = (
      <ReactPlayer
        controls={true}
        width="100%"
        height="360px"
        muted
        url={url.toJSON()}
      />
    );
  }

  return <Box my={1}>{view}</Box>;
};
