import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { FullPageDialog } from "../FullPageDialog";
import { ExerciseForm } from "./ExerciseForm";
import { useExercise } from "../../hooks/useExercises";

export const ExerciseDialog = ({ open, initialValue, onClose }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const { onCreate, onUpdate } = useExercise();

  useEffect(() => {
    if (open) {
      setValue(initialValue);
    } else {
      setValue(null);
    }
  }, [open, initialValue]);

  const handleSubmit = async (value) => {
    setLoading(true);
    if (value.id) {
      await onUpdate(value);
    } else {
      await onCreate(value);
    }
    setLoading(false);
    onClose();
  };

  return (
    <FullPageDialog open={open} onClose={onClose} title="Упражнение">
      <Box p={2}>
        <ExerciseForm
          value={value}
          onChange={setValue}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </Box>
    </FullPageDialog>
  );
};
