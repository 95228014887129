import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles(() => ({
  "@global": {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
    },
    "#root": {
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    },
  },
}));

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#7165E3",
    },
    secondary: {
      main: "#3D3D3D",
    },
    background: {
      default: "#f4f5fa",
    },
    text: {
      primary: "#3d3d3d",
      secondary: "#afb1b6",
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#EFEFEF",
    },
  },
});
