import React from "react";

import { Page } from "../components/Page";
import { ProfileForm } from "../components/Profile/ProfileForm";
import { Loading } from "../components/Loading";
import { useSettings } from "../hooks/useSettings";

export const ProfilePage = ({ userId }) => {
  const { profile, email, loading, onUpdateProfile } = useSettings({
    userId,
  });

  return (
    <Page headerProps={{ title: email }}>
      {loading ? (
        <Loading />
      ) : (
        <ProfileForm initialValue={profile} onSubmit={onUpdateProfile} />
      )}
    </Page>
  );
};
