import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import dayjs from "../services/dayjs";
import { useStore } from "../hooks/useStore";
import { MainPage } from "./MainPage";
import { TrainerMainPage } from "./TrainerMainPage";
import { CalendarPage } from "./CalendarPage";
import { ExercisePage } from "./ExercisePage";
import { MealPage } from "./MealPage";
import { ProfilePage } from "./ProfilePage";
import { DietPage } from "./DietPage";

export const Routes = () => {
  const {
    state: { users, isTrainer },
  } = useStore();

  return (
    <Switch>
      <Route
        path="/calendar/:userId/:day/:action?"
        render={({ match: { params } }) => {
          const day = dayjs(params.day);
          const user = users.find(({ id }) => params.userId === id);

          if (!user || !day.isValid()) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          return (
            <CalendarPage day={day} user={user} action={params.action || ""} />
          );
        }}
      />
      <Route
        path="/exercise/:action?"
        render={({ match: { params } }) => {
          return <ExercisePage action={params.action || ""} />;
        }}
      />
      <Route
        path="/meal/:action?"
        render={({ match: { params } }) => {
          return <MealPage action={params.action || ""} />;
        }}
      />
      <Route
        path="/profile/:userId"
        render={({ match: { params } }) => {
          const user = users.find(({ id }) => params.userId === id);

          if (!user) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          return <ProfilePage userId={user.id} />;
        }}
      />
      <Route
        path="/diet/:userId"
        render={({ match: { params } }) => {
          const user = users.find(({ id }) => params.userId === id);

          if (!user) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          return <DietPage userId={user.id} view={!isTrainer} />;
        }}
      />
      <Route
        path="/"
        render={() => {
          return isTrainer ? <TrainerMainPage /> : <MainPage />;
        }}
      />
    </Switch>
  );
};
