import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Media } from "../Media";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  diet: {
    whiteSpace: "pre-line",
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export const DietView = ({ diet, meals }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.diet}>{diet || "Питание еще не заполнено!"}</Typography>
      {meals.map(({ id, title, description, media }) => (
        <Box position="relative" key={id} my={2}>
          <LocalDiningIcon color="disabled" className={classes.icon} />
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
          {media.map((item) => (
            <Media key={item.id} media={item} />
          ))}
        </Box>
      ))}
    </Fragment>
  );
};
