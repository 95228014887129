import React from "react";
import { LoginPage } from "../pages/LoginPage";
import { Loading } from "../components/Loading";
import { useStore } from "../hooks/useStore";
import { useAuth } from "../hooks/useAuth";

export const AuthGuard = ({ children }) => {
  const {
    state: { me },
  } = useStore();
  const { initialLoading, loading, onLogin } = useAuth();

  if (initialLoading) {
    return <Loading />;
  }

  if (!me) {
    return <LoginPage onLogin={onLogin} loading={loading} />;
  }

  return children;
};
