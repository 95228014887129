import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import { FullPageDialog } from "../FullPageDialog";
import { Messages } from "./Messages";
import { Media } from "../Media";

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.h6,
  },
  description: {
    marginBottom: theme.spacing(1),
    ...theme.typography.subtitle2,
  },
  divider: {
    borderTop: `1px dashed ${theme.palette.primary.main}`,
    borderBottom: `1px dashed ${theme.palette.primary.main}`,
  },
  exercise: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export const MessageDialog = ({ open, uuid, exercise = {}, onClose }) => {
  const classes = useStyles();
  const { title, description, attach = [] } = exercise;

  return (
    <FullPageDialog open={open} title="Сообщения" onClose={onClose}>
      <Box p={2}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </Box>
      {attach.length > 0 && (
        <Box
          position="relative"
          className={classes.divider}
          bgcolor="grey.200"
          p={2}
          mb={4}
        >
          <FitnessCenterIcon color="disabled" className={classes.exercise} />
          {attach.map(({ id, title, description, media }) => (
            <Fragment key={id}>
              <Typography variant="subtitle2">{title}</Typography>
              <Typography variant="body2">{description}</Typography>
              {media.map((item) => (
                <Media key={item.id} media={item} />
              ))}
            </Fragment>
          ))}
        </Box>
      )}
      <Box px={2} pb={8}>
        {!!uuid && <Messages uuid={uuid} />}
      </Box>
    </FullPageDialog>
  );
};
