import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import LocalDiningIcon from "@material-ui/icons/LocalDining";

import { UserHeader } from "../components/UserHeader";
import { useStore } from "../hooks/useStore";
import { today } from "../services/dayjs";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[6],
  },
}));

export const TrainerMainPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { users },
  } = useStore();

  return (
    <Fragment>
      <UserHeader />
      <Box px={2}>
        <Card className={classes.card}>
          <List>
            <ListItem button component={Link} to="/exercise/">
              <ListItemAvatar>
                <Avatar>
                  <FitnessCenterIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="База упражнений" />
              <NavigateNextIcon />
            </ListItem>
          </List>
        </Card>
        <Card className={classes.card}>
          <List>
            <ListItem button component={Link} to="/meal/">
              <ListItemAvatar>
                <Avatar>
                  <LocalDiningIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Продукты питания" />
              <NavigateNextIcon />
            </ListItem>
          </List>
        </Card>
        {users.map((user) => (
          <Card key={user.id} className={classes.card}>
            <CardHeader
              onClick={() => history.push(`/calendar/${user.id}/${today}/`)}
              titleTypographyProps={{ variant: "h6" }}
              title={user.username}
              subheader={user.email}
            />
            <CardActions>
              <Button
                component={Link}
                to={`/calendar/${user.id}/${today}/`}
                color="primary"
                size="small"
              >
                Тренировки
              </Button>
              <Button
                component={Link}
                to={`/diet/${user.id}/`}
                color="primary"
                size="small"
              >
                Питание
              </Button>
              <Button
                component={Link}
                to={`/profile/${user.id}/`}
                color="primary"
                size="small"
              >
                Профиль
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Fragment>
  );
};
