import { useMutation, useQuery, gql } from "@apollo/client";
import get from "lodash/get";
import { useStore } from "./useStore";

const EXERCISES = gql`
  query exercises($where: JSON!) {
    exercises(where: $where) {
      id
      title
      description
      media {
        id
        url
        mime
      }
      author {
        id
      }
    }
  }
`;

export const useExercises = () => {
  const { data } = useQuery(EXERCISES, {
    variables: {
      where: {
        enabled: true,
      },
    },
  });

  const exercises = get(data, "exercises", []);
  const exercisesHash = exercises.reduce(
    (acc, { id, title }) => ({ ...acc, [id]: title }),
    {}
  );

  return { exercises, exercisesHash };
};

const EXERCISE_CREATE = gql`
  mutation createExercise($input: createExerciseInput!) {
    createExercise(input: $input) {
      exercise {
        id
      }
    }
  }
`;

const EXERCISE_UPDATE = gql`
  mutation updateExercise($input: updateExerciseInput!) {
    updateExercise(input: $input) {
      exercise {
        id
      }
    }
  }
`;

export const useExercise = () => {
  const {
    state: { me },
  } = useStore();
  const [createExercise] = useMutation(EXERCISE_CREATE, {
    refetchQueries: ["exercises"],
  });
  const [updateExercise] = useMutation(EXERCISE_UPDATE, {
    refetchQueries: ["exercises"],
  });

  const onCreate = async ({ media = [], ...data }) => {
    await createExercise({
      variables: {
        input: {
          data: {
            ...data,
            author: me.id,
            media: media.map(({ id }) => id),
          },
        },
      },
    });
  };

  const onUpdate = async ({ id, author, media = [], ...data }) => {
    await updateExercise({
      variables: {
        input: {
          where: { id },
          data: {
            ...data,
            media: media.map(({ id }) => id),
          },
        },
      },
    });
  };

  return { onCreate, onUpdate };
};
