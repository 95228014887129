import { useMutation, useQuery, gql } from "@apollo/client";
import get from "lodash/get";

const SETTINGS = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      settings {
        id
        profile
        diet
        meals {
          id
          title
          description
          enabled
          media {
            id
            url
            mime
          }
        }
      }
    }
  }
`;

const SETTINGS_UPDATE = gql`
  mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const useSettings = ({ userId }) => {
  const { data, loading } = useQuery(SETTINGS, {
    variables: {
      userId,
    },
  });
  const [updateSettings] = useMutation(SETTINGS_UPDATE, {
    refetchQueries: ["user"],
  });

  const settingsId = get(data, "user.settings.id", undefined);

  const onUpdateProfile = async ({ username, ...profile }) => {
    await updateSettings({
      variables: {
        input: {
          where: { id: userId },
          data: {
            username,
            settings: {
              id: settingsId,
              profile,
            },
          },
        },
      },
    });
  };

  const onUpdateDiet = async ({ diet, meals }) => {
    await updateSettings({
      variables: {
        input: {
          where: { id: userId },
          data: {
            settings: {
              id: settingsId,
              diet,
              meals,
            },
          },
        },
      },
    });
  };

  const username = get(data, "user.username", "");
  const email = get(data, "user.email", "");
  const gender = get(data, "user.settings.profile.gender", "") || "";
  const birthDate = get(data, "user.settings.profile.birthDate", "") || "";
  const weight = get(data, "user.settings.profile.weight", "") || "";
  const height = get(data, "user.settings.profile.height", "") || "";
  const description = get(data, "user.settings.profile.description", "") || "";
  const diet = get(data, "user.settings.diet", "") || "";
  const meals = get(data, "user.settings.meals", []) || [];

  return {
    loading,
    email,
    profile: {
      username,
      gender,
      birthDate,
      weight,
      height,
      description,
    },
    diet: { diet, meals },
    onUpdateDiet,
    onUpdateProfile,
  };
};
