import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Routes } from "./pages";
import { AuthGuard } from "./containers/AuthGuard";
import { AppStoreProvider } from "./containers/AppStore";
import { theme, useGlobalStyles } from "./theme";
import { client } from "./api/client";

const App = () => {
  useGlobalStyles();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppStoreProvider>
            <AuthGuard>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Routes />
              </MuiPickersUtilsProvider>
            </AuthGuard>
          </AppStoreProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
