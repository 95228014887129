import { useMutation, useQuery, gql } from "@apollo/client";
import get from "lodash/get";
import { useStore } from "./useStore";

const MEALS = gql`
  query meals($where: JSON!) {
    meals(where: $where) {
      id
      title
      description
      media {
        id
        url
        mime
      }
      author {
        id
      }
    }
  }
`;

export const useMeals = () => {
  const { data } = useQuery(MEALS, {
    variables: {
      where: {
        enabled: true,
      },
    },
  });

  const meals = get(data, "meals", []);
  const mealsHash = meals.reduce(
    (acc, { id, title }) => ({ ...acc, [id]: title }),
    {}
  );

  return { meals, mealsHash };
};

const MEAL_CREATE = gql`
  mutation createMeal($input: createMealInput!) {
    createMeal(input: $input) {
      meal {
        id
      }
    }
  }
`;

const MEAL_UPDATE = gql`
  mutation updateMeal($input: updateMealInput!) {
    updateMeal(input: $input) {
      meal {
        id
      }
    }
  }
`;

export const useMeal = () => {
  const {
    state: { me },
  } = useStore();
  const [createMeal] = useMutation(MEAL_CREATE, {
    refetchQueries: ["meals"],
  });
  const [updateMeal] = useMutation(MEAL_UPDATE, {
    refetchQueries: ["meals"],
  });

  const onCreate = async ({ media = [], ...data }) => {
    await createMeal({
      variables: {
        input: {
          data: {
            ...data,
            author: me.id,
            media: media.map(({ id }) => id),
          },
        },
      },
    });
  };

  const onUpdate = async ({ id, author, media = [], ...data }) => {
    await updateMeal({
      variables: {
        input: {
          where: { id },
          data: {
            ...data,
            media: media.map(({ id }) => id),
          },
        },
      },
    });
  };

  return { onCreate, onUpdate };
};
