import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { MealHeader } from "../components/Meal/MealHeader";
import { MealDialog } from "../components/Meal/MealDialog";
import { useMeals } from "../hooks/useMeal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  addEvent: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 1,
  },
}));

export const MealPage = ({ action }) => {
  const [searchValue, changeSearchValue] = useState("");
  const history = useHistory();
  const classes = useStyles();
  let { meals } = useMeals();

  const handleChangeAction = (action) => {
    history.push(`/meal/${action || ""}`);
  };

  const [actionType, actionId] = action.split("-");
  let initialValue = meals.find(({ id }) => id === actionId);

  if (actionType === "create") {
    initialValue = {
      title: "",
      description: "",
      media: [],
    };
  }

  if (searchValue.length) {
    meals = meals.filter(
      ({ title }) =>
        title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  return (
    <Fragment>
      <MealHeader
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
      />
      <Box bgcolor="background.default" position="relative">
        <List>
          {meals.map(({ id, title }) => (
            <ListItem
              button
              key={id}
              onClick={() => handleChangeAction(`update-${id}`)}
            >
              <ListItemText primary={title} />
              <NavigateNextIcon />
            </ListItem>
          ))}
        </List>
      </Box>
      <MealDialog
        initialValue={initialValue}
        open={["create", "update"].includes(actionType)}
        onClose={() => handleChangeAction(null)}
      />
      <Fab
        color="primary"
        className={classes.addEvent}
        onClick={() => handleChangeAction("create-meal")}
      >
        <AddIcon />
      </Fab>
    </Fragment>
  );
};
