import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SubjectIcon from "@material-ui/icons/Subject";
import TextField from "@material-ui/core/TextField";

export const TitleField = ({ value, onChange, ...rest }) => (
  <TextField
    placeholder="Название упражнения"
    margin="normal"
    variant="outlined"
    size="small"
    value={value}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SubjectIcon />
        </InputAdornment>
      ),
    }}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    {...rest}
  />
);

export const DescriptionField = ({ value, onChange, ...rest }) => (
  <TextField
    placeholder="Описание"
    variant="outlined"
    size="small"
    fullWidth
    multiline
    rows={7}
    value={value}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    {...rest}
  />
);
