const TOKEN_KEY = "userToken";

export const setToken = (jwt) => {
  localStorage.setItem(TOKEN_KEY, jwt);
};
export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};
