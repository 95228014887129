import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useSwipeable } from "react-swipeable";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { Calendar } from "../components/Calendar";
import { toIsoDate } from "../services/dayjs";
import { useWorkouts } from "../hooks/useWorkouts";
import { Workout } from "../components/Event/Workout";
import { WorkoutMenu } from "../components/Event/WorkoutMenu";
import { WorkoutDialog } from "../components/Event/WorkoutDialog";
import { MessageDialog } from "../components/Event/MessageDialog";
import { useStore } from "../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  addEvent: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: theme.zIndex.appBar + 1,
  },
}));

export const CalendarPage = ({ day, user, action }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { isTrainer },
  } = useStore();
  const { exercises, workouts, workoutsByWeek } = useWorkouts({
    day,
    userId: user.id,
  });

  const handleChangeAction = (action) => {
    history.push(`/calendar/${user.id}/${toIsoDate(day)}/${action || ""}`);
  };

  const handleChangeDay = (day) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    history.push(`/calendar/${user.id}/${toIsoDate(day)}/`);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleChangeDay(day.add(1, "day"));
    },
    onSwipedRight: () => {
      handleChangeDay(day.add(-1, "day"));
    },
  });

  const [actionType, actionId] = action.split("-");
  const workout = workouts.find(({ id }) => id === actionId);
  const exercise = exercises[actionId];

  let initialValue = workout;
  let uuid = null;

  if (actionType === "create") {
    initialValue = {
      date: toIsoDate(day),
      user: user.id,
      title: "",
      exercise: [
        {
          title: "",
          description: "",
        },
      ],
    };
  }

  if (actionType === "clone" && workout) {
    initialValue = {
      title: workout.title,
      user: user.id,
      date: null,
      exercise: workout.exercise.map(({ title, description, attach }) => ({
        title,
        description,
        attach,
      })),
    };
  }

  if (actionType === "chat" && exercise) {
    uuid = `exercise::${exercise.id}`;
  }

  return (
    <Fragment>
      <Calendar
        value={day}
        user={user}
        onChange={handleChangeDay}
        daysWithEvents={workoutsByWeek}
      />
      <Box
        p={2}
        bgcolor="background.default"
        position="relative"
        {...handlers}
        flexGrow={1}
      >
        {workouts.map((workout) => (
          <Workout
            value={workout}
            key={workout.id}
            canEdit={isTrainer}
            onChangeAction={handleChangeAction}
          />
        ))}
      </Box>
      <WorkoutMenu
        open={actionType === "menu"}
        value={workout}
        onChangeAction={handleChangeAction}
      />
      <WorkoutDialog
        open={["create", "update", "clone"].includes(actionType)}
        initialValue={initialValue}
        onClose={() => handleChangeAction(null)}
      />
      <MessageDialog
        open={actionType === "chat"}
        uuid={uuid}
        exercise={exercise}
        onClose={() => handleChangeAction(null)}
      />
      {isTrainer && (
        <Fab
          color="primary"
          className={classes.addEvent}
          onClick={() => handleChangeAction("create-workout")}
        >
          <AddIcon />
        </Fab>
      )}
    </Fragment>
  );
};
