import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import RemoveIcon from "@material-ui/icons/DeleteForever";
import SubjectIcon from "@material-ui/icons/Subject";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "@material-ui/pickers";
import { toIsoDate } from "../../services/dayjs";

export const TitleField = ({ value, onChange, ...rest }) => (
  <TextField
    margin="normal"
    variant="outlined"
    size="small"
    value={value}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SubjectIcon />
        </InputAdornment>
      ),
    }}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    {...rest}
  />
);

export const DateField = ({ value, onChange }) => (
  <DatePicker
    format="DD.MM.YYYY"
    margin="normal"
    inputVariant="outlined"
    size="small"
    value={value}
    autoOk
    onChange={(date) => {
      onChange(toIsoDate(date));
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <InsertInvitationIcon />
        </InputAdornment>
      ),
    }}
  />
);

export const TitleItemField = ({
  value,
  removable,
  onChange,
  onRemove,
  ...rest
}) => (
  <TextField
    size="small"
    fullWidth
    margin="normal"
    variant="outlined"
    value={value}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    InputProps={{
      endAdornment: removable ? (
        <InputAdornment position="end">
          <RemoveIcon onClick={onRemove} color="primary" />
        </InputAdornment>
      ) : undefined,
    }}
    {...rest}
  />
);

export const DescriptionItemField = ({ value, onChange, ...rest }) => (
  <TextField
    placeholder="Описание"
    variant="outlined"
    size="small"
    fullWidth
    multiline
    rows={3}
    value={value}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    {...rest}
  />
);
