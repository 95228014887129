import React, { useState } from "react";
import set from "lodash/fp/set";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Add from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  DateField,
  DescriptionItemField,
  TitleField,
  TitleItemField,
} from "./Fields";
import { schemaWorkout } from "./schema";
import { useExercises } from "../../hooks/useExercises";

export const WorkoutForm = ({ value, onChange, onSubmit, loading }) => {
  const { exercisesHash } = useExercises();
  const [error, setError] = useState(null);
  const handleForm = (field) => (val) => {
    onChange(set(field, val, value));
  };
  const handleAddItem = () => {
    handleForm("exercise")(
      value.exercise.concat({ title: "", description: "", attach: [] })
    );
  };
  const handleRemoveItem = (removeIndex) => {
    handleForm("exercise")(
      value.exercise.filter((_, index) => index !== removeIndex)
    );
  };
  const handleSubmit = async () => {
    try {
      await schemaWorkout.validate(value).then(onSubmit);
    } catch (e) {
      setError(e.message);
    }
  };

  const handleErrorClose = () => {
    setError(null);
  };

  if (!value) {
    return null;
  }

  return (
    <FormGroup>
      <DateField value={value.date} onChange={handleForm("date")} />
      <TitleField
        placeholder="Название тренировки"
        value={value.title}
        onChange={handleForm("title")}
      />
      <Box my={2}>
        <Card>
          {value.exercise.map((item, index) => {
            return (
              <Box
                style={{ borderBottom: "1px dashed #ccc" }}
                pb={2}
                px={2}
                key={index}
              >
                <TitleItemField
                  placeholder="Название упражнения"
                  value={item.title}
                  onChange={handleForm(`exercise.${index}.title`)}
                  removable={value.exercise.length > 1}
                  onRemove={() => {
                    handleRemoveItem(index);
                  }}
                />
                <DescriptionItemField
                  value={item.description}
                  onChange={handleForm(`exercise.${index}.description`)}
                />
                <Autocomplete
                  multiple
                  value={item.attach}
                  ChipProps={{
                    color: "primary",
                    size: "small",
                    style: { maxWidth: 180 },
                  }}
                  filterSelectedOptions
                  options={Object.keys(exercisesHash)}
                  getOptionLabel={(option) => exercisesHash[option]}
                  onChange={(e, val) => {
                    handleForm(`exercise.${index}.attach`)(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Упражнения"
                      size="small"
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              </Box>
            );
          })}
          <CardActions>
            <Button
              disabled={loading}
              size="small"
              color="primary"
              onClick={handleAddItem}
              startIcon={<Add />}
            >
              Добавить
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Сохранить
        </Button>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </FormGroup>
  );
};
