import React, { useState } from "react";
import { clearToken } from "../services/token";

const initialState = { me: null, isTrainer: false, users: [] };

export const AppContext = React.createContext(initialState);

export const AppStoreProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const login = (payload) => {
    const { users, ...me } = payload;
    const isTrainer = me.role.type === "trainer";

    setState({ me, isTrainer, users: [me, ...users] });
  };

  const logout = () => {
    clearToken();
    setState(initialState);
  };

  return (
    <AppContext.Provider value={{ state, actions: { login, logout } }}>
      {children}
    </AppContext.Provider>
  );
};
