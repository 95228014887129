import React, { useState, Fragment } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverButton from "@material-ui/icons/DeleteForever";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import { UploadFile } from "../UploadFile";
import { Media } from "../Media";
import { useMessages, useMessage } from "../../hooks/useMessage";
import { useStore } from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  message: {
    borderStyle: "solid",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1.5, 0, 1.5, 0),
    borderWidth: "1px",
    whiteSpace: "pre-line",
    ...theme.typography.body1,
  },
  info: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  appBar: {
    top: "auto",
    bottom: 0,
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(1, 1, 1, 2),
  },
  textarea: {
    width: "100%",
    display: "block",
    padding: theme.spacing(1.2, 2),
    outline: "none",
    borderColor: "transparent",
    borderRadius: theme.spacing(2),
    borderWidth: "2px",
    resize: "none",
    background: theme.palette.grey[100],
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
    ...theme.typography.body1,
  },
}));

export const Messages = ({ uuid }) => {
  const classes = useStyles();
  const {
    state: { me },
  } = useStore();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { messages } = useMessages({ uuid });
  const { onCreate, onUpdate } = useMessage({ uuid });

  const handleSendMessage = async () => {
    setLoading(true);
    await onCreate({ message });
    setMessage("");
    setLoading(false);
  };

  const handleUploadStart = async () => {
    setLoading(true);
  };

  const handleUploadComplete = async (media) => {
    if (media) {
      await onCreate({ media: [media.id] });
    }

    setLoading(false);
  };

  const handleRemoveMessage = async (id) => {
    // eslint-disable-next-line
    if (confirm("Удалить сообщение?")) {
      await onUpdate({ id, enabled: false });
    }
  };

  return (
    <Fragment>
      {messages.map(({ id, media, message, author, date }) => (
        <Fragment key={id}>
          {media.map((media) => (
            <Media media={media} key={media.id} />
          ))}
          {!!message && <div className={classes.message}>{message}</div>}
          <div className={classes.info}>
            {me.id === author.id && (
              <DeleteForeverButton
                fontSize="small"
                color="primary"
                onClick={() => handleRemoveMessage(id)}
              />
            )}
            <Typography variant="caption" color="textSecondary">
              {author.username} / {date}
            </Typography>
          </div>
        </Fragment>
      ))}
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <TextareaAutosize
          className={classes.textarea}
          rowsMin={1}
          rowsMax={4}
          maxLength={1500}
          value={message}
          disabled={loading}
          onChange={(e) => setMessage(e.target.value)}
        />
        {message.length === 0 ? (
          <UploadFile
            disabled={loading}
            onStart={handleUploadStart}
            onComplete={handleUploadComplete}
          />
        ) : (
          <IconButton
            disabled={loading}
            onClick={handleSendMessage}
            color="primary"
          >
            <SendIcon />
          </IconButton>
        )}
      </AppBar>
    </Fragment>
  );
};
