import React, { Fragment, useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import { virtualize } from "react-swipeable-views-utils";
import { makeStyles } from "@material-ui/core/styles";
import TodayIcon from "@material-ui/icons/Today";

import dayjs, { toIsoDate } from "../services/dayjs";
import { Header } from "./Header";

const useStyles = makeStyles((theme) => ({
  offset: {
    height: theme.spacing(20),
  },
  appBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(1),
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[8],
  },
  week: {
    display: "flex",
    justifyContent: "space-between",
  },
  day: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    borderRadius: "24px",
    transition: "background-color 0.2s ease-out",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0.2),
  },
  dayWeek: {
    opacity: 0.7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.spacing(3.5),
  },
  dayDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  selectedDay: {
    backgroundColor: theme.palette.primary.dark,
    "& $dayWeek": {
      opacity: 1,
    },
    "& $dayDate": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
  },
  eventOnDay: {
    position: "absolute",
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    background: theme.palette.primary.contrastText,
    borderRadius: "50%",
    bottom: 0,
    transition: "transform 0.1s ease-in-out",
  },
  eventOnDayHidden: {
    transform: "scale(0)",
  },
}));

const VisualizeSizeableViews = virtualize(SwipeableViews);
const firstSlide = dayjs().startOf("week");
let lastIndex = null;

export const Calendar = ({ value, user, daysWithEvents, onChange }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const indexByDay = Math.floor(value.diff(firstSlide, "day") / 7);

  useEffect(() => {
    setCurrentIndex(indexByDay);
  }, [indexByDay]);

  const handleChangeIndex = (index) => {
    lastIndex = index;
    setCurrentIndex(index);
  };

  const handleTransitionEnd = () => {
    if (lastIndex === null) {
      return;
    }

    onChange(firstSlide.add(lastIndex, "week").startOf("week"));
    lastIndex = null;
  };

  const slideRenderer = ({ key, index }) => (
    <div className={classes.week} key={key}>
      {[0, 1, 2, 3, 4, 5, 6].map((diff) => {
        const day = firstSlide.add(diff + index * 7, "day");
        const isSelected = day.isSame(value, "day");
        const showEventDot =
          !isSelected && daysWithEvents.indexOf(toIsoDate(day)) > -1;

        return (
          <ButtonBase
            className={clsx(classes.day, { [classes.selectedDay]: isSelected })}
            key={diff}
            onClick={() => {
              onChange(day);
            }}
          >
            <Typography
              color="inherit"
              variant="subtitle2"
              className={classes.dayWeek}
            >
              {day.format("dd").toUpperCase()}
            </Typography>
            <Typography
              color="inherit"
              variant="subtitle2"
              className={classes.dayDate}
            >
              {day.format("DD")}
            </Typography>
            <div
              className={clsx(classes.eventOnDay, {
                [classes.eventOnDayHidden]: !showEventDot,
              })}
            />
          </ButtonBase>
        );
      })}
    </div>
  );

  return (
    <Fragment>
      <div className={classes.appBar}>
        <Header
          title={user.username}
          actionIcon={
            <IconButton
              color="inherit"
              onClick={() => {
                onChange(dayjs());
              }}
            >
              <TodayIcon color="inherit" />
            </IconButton>
          }
        />
        <VisualizeSizeableViews
          onChangeIndex={handleChangeIndex}
          onTransitionEnd={handleTransitionEnd}
          slideRenderer={slideRenderer}
          index={currentIndex}
          hysteresis={0.05}
        />
      </div>
      <div className={classes.offset} />
    </Fragment>
  );
};
